:root {
  --open: #52C41A;
  --close: #FF4D4F;
  --proposal: #FF9C07;
}

.status {
  width: 80%;
  margin: 0 auto;
  padding: 6px 12px;
  border-radius: 6px;
  color: white;

  &.open {
    background-color: var(--open);
  }

  &.close {
    background-color: var(--close);
  }
}

.regis-topic-action {
  .disable {
    color: #a0a0a0 !important;
  }

  .proposal {
    color: #009ef7;
  }

  .registration {
    color: #009ef7;
  }
  .edit {
    color: #009ef7;
  }
  .no-edit {
    color: #a0a0a0;
  }
}

.btn-filter,
.btn-filter:hover,
.btn-filter:active,
.btn-filter:focus {
  font-weight: bold;
  color: var(--bs-btn-primary) !important;
  border-color: var(--bs-btn-primary) !important;

  i {
    color: var(--bs-btn-primary) !important;
  }
}

.max-h-80vh {
  max-height: 80vh;
}

.table-responsive {
  max-height: 60vh;
  min-height: 300px;
}

#ds-dot-dang-ky #kt_table_users tr th, #dx-ds-thanh-vien #kt_table_users tr th {
  background-color: var(--color-header-table-custom) !important;
  color: white !important;

  &:hover {
    background-color: var(--color-header-table-custom) !important;
  }
}

#assignment-implement-form {
  * {
    font-size: 1.2rem !important;
  }

  .table-ds-de-tai {
    width: 100%;
    * {
      font-size: 1.15rem !important;
    }

    td,
    th {
      border: 1px solid black;
      padding: 0 4px !important;
    }
  }
}