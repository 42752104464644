@import "../../styles/variable";

.form-thong-ke {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 490px);
}

.form-them-moi-vat-tu {
  margin-top: 5px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 432px);
}

.title-color-ttc {
  background-color: #17a2b8;
  color: white !important;
  padding: 0px 30px;
  line-height: 28px;
}

.title-back {
  color: var(--color-header-table-custom);
  border: 1px solid var(--color-header-table-custom);
  border-radius: 4px;
}

.tree {
  border: 1px solid #cecece;
  border-left: none;
  line-height: 21px;
  width: 25%;
  font-size: $default-font-size;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 229px);

  .quantity {
    border: 1px solid #17a2b8;
    border-radius: 5px;
    color: #fff;
    color: #17a2b8;
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    margin: 2.45px 0;
    padding: 2px;
    text-align: center;
    width: 16px;
    line-height: 10.5px;
  }
}

.isSelected {
  color: white;
  background: #42beb1;
  border-radius: 0.475rem;
  background-color: #f6f6f6;
  border: 1px solid #c9bfb3;
  padding: 6px;
}

.h-70px {
  min-height: 70.5px !important;
}

.isFilterSelected {
  width: 93%;
  color: $color-text-cyan;
  background: #e6fffb;
  // border-right: #08979c 3px solid;
  line-height: 21px;
  // padding: 10px;
}

.hover-row {
  &:hover {
    width: 93%;
    color: $color-text-cyan;
    background: #e6fffb;
    // border-right: #08979c 3px solid;
    line-height: 21px;
  }

  line-height: 21px;
  width: 93%;
}

.h-bang-ds-vt {
  height: 300px;
}

.w-form-add {
  width: calc(200vh - 10px) !important;
}

.dropdown-menu.show {
  display: block;
}

.border-black {
  border-color: black !important;
}

.table-topic-product,
.table-topic-product th,
.table-topic-product td {
  border: 1px solid black !important;
  border-collapse: collapse;
}
.table-topic-product td:first-child {
  padding-left: 0.75rem;
}
.accordion-header {
  .accordion-button {
    color: var(--color-header-table-custom) !important;
    font-weight: bold;
    font-size: 14px;
  }
}
.topic-action-dropdown {
  .dropdown-menu.show {
    flex-direction: column;
    li {
      width: 100%;
    }
  }
}
.table-responsive {
  max-height: 60vh;
  min-height: 300px;
}
#ds-de-tai #kt_table_users tr th {
  background-color: var(--color-header-table-custom) !important;
  color: white !important;

  &:hover {
    background-color: var(--color-header-table-custom) !important;
  }
}
//

.a4-page {
  width: 700px;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: "Times New Roman", Times, serif;
}

@media print {
  .a4-page {
    page-break-after: always;
  }
}

.text-center {
  text-align: center;
}
.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.container {
  margin: 20px auto;
  width: 100%;
}
.section {
  margin-top: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.signature {
  margin-top: 50px;
  text-align: center;
}
.signature p {
  margin-bottom: 100px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  text-align: center;
}
.left-column {
  text-align: left;
}
.right-column {
  text-align: right;
}
.center-text {
  text-align: center;
  margin-top: 20px;
}
.signature-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
}

.left-signature,
.right-signature {
  width: 45%;
  text-align: center;
}

.left-signature p,
.right-signature p {
  margin: 0;
}
.input-field {
  border: none;
  outline: none;
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 24px;
  resize: none;
}

.dot-line {
  position: absolute;
  bottom: -100%;
  right: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed;
  background-color: transparent;
  transform: translateY(-16px);
}

.custom-input:after {
  content: unset !important;
}
.custom-input:before {
  content: unset !important;
  width: 100%;
}

.custom-input {
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.relative {
  position: relative;
}
.no-padding {
  padding: 0 !important;

  display: flex;
}
.validator-input:required:valid {
  border-color: green;
}
.validator-input:invalid {
  border-color: red;
}
.mark-table{
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
  margin-bottom: 12px;
  thead{
    td {
      text-align: center;
      border: 1px solid black;
    }
  }
  tbody{
    td {
      padding: 4px;
      border: 1px solid black;
      &:not(:nth-child(2)){
        text-align: center;
      }
    }
  }
}

.form-evaluation {
  font-size: medium;
  overflow-y: auto;
}

.pl-2 {
  padding-left: 2px;
}
.name-topic {
  font-weight: bold;
}
.outline-border {
  outline: none;
  border: none;
}
.text-field {
  outline: none;
  border: none;
  border-bottom: 1px dashed #000;
  width: 80%;
  margin-left: 12px;
}
.field-name {
  width: 100%;
  margin-left: 0px;
}
.date-field {
  outline: none;
  border: none;
  border-bottom: 1px dashed #000;
  margin-left: 12px;
  width: 20%;
}
.mb-2 {
  margin-bottom: 24px;
}
.table-bordered {
  border: 1px solid black;

  td, th {
    border: 1px solid black;
  }

  tr {
    border: 1px solid black;
  }
}

#form-evaluation {
  * {
    font-size: 1.2rem !important;
  }

  textarea {
    font-size: 16px !important;
  }
}

#form-science-minutes {
  * {
    font-size: 1.2rem !important;
  }

  table {
    td {
      font-size: 12px !important;
    }
  }

  textarea {
    font-size: 16px !important;
  }
}