:root {
  --open: #52C41A;
  --close: #FF4D4F;
  --proposal: #FF9C07;
}

.btn-filter,
.btn-filter:hover,
.btn-filter:active,
.btn-filter:focus {
  font-weight: bold;
  color: var(--bs-btn-primary) !important;
  border-color: var(--bs-btn-primary) !important;

  i {
    color: var(--bs-btn-primary) !important;
  }
}

.table-responsive {
  max-height: 60vh;
  min-height: 300px;
}

.tab-topic-details.nav-tabs {
  .nav-link.active{
      color: var(--color-header-table-custom);
      font-weight: bold;
      border-color: var(--color-header-table-custom);
      border-bottom: unset;
  }
}

#ds-de-tai-cua-toi #kt_table_users tr th, 
#outline-organization #kt_table_users tr th, 
#outline-tab-organization #kt_table_users tr th ,
#dx-tab-member #kt_table_users tr th,
#officer-organization #kt_table_users tr th
{
  background-color: var(--color-header-table-custom) !important;
  color: white !important;

  &:hover {
    background-color: var(--color-header-table-custom) !important;
  }
}
.topic-details{
  .a4-page {
    width: 800px;
    margin: 20px auto;
    box-sizing: border-box;
    font-family: "Times New Roman", Times, serif;
    box-shadow: 1px 1px 3px 1px #cecece;
    .a4-container{
      padding-top: 30px;
      padding-left: 80px;
      padding-right: 40px;
      padding-bottom: 50px;
      & * {
        font-size: 1.3rem!important;
      }
    }
  }
  
  @media print {
    .a4-page {
      page-break-after: always;
    }
  }
  
  .text-center {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .underline {
    text-decoration: underline;
  }
  .container {
    margin: 20px auto;
    width: 100%;
  }
  .section {
    margin-top: 20px;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .signature {
    margin-top: 50px;
    text-align: center;
  }
  .signature p {
    margin-bottom: 100px;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    text-align: center;
  }
  .left-column {
    text-align: left;
  }
  .right-column {
    text-align: right;
  }
  .center-text {
    text-align: center;
    margin-top: 20px;
  }
  .signature-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  
  .left-signature,
  .right-signature {
    width: 45%;
    text-align: center;
  }
  
  .left-signature p,
  .right-signature p {
    margin: 0;
  }
  .input-field {
  border: none;
  outline: none;
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 24px;
  resize: none; 
  }
  
  .dot-line {
  position: absolute;
  bottom: -100%;
  right: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed;
  background-color: transparent;
  transform: translateY(-16px);
  }
  
  .custom-input:after {
  content: unset !important;
  }
  .custom-input:before {
  content: unset !important;
  width: 100%;
  }
  
  .custom-input {
  border: none;
  outline: none;
  resize: none; 
  width: 100%;
  box-sizing: border-box; 
  display: block;
  }
  
  .relative {
  position: relative;
  overflow: hidden; 
  }
  .no-padding {
  padding: 0 !important;
  
  display: flex;
  
  }
}

.implementation-progress, .app-product, .science-product, .edu-product, .hiring-machine, .buy-machine, .funding-requirement, .comment-review{
  width: 100%;
  border-collapse: collapse;
  th{
    text-align: center;
    border: 1px solid #E4E6EF;
    padding: 10px!important;
    background-color: #3c78d8;
    color: white;
  }
  thead {
    td {
      border: 1px solid #E4E6EF;
      padding: 10px!important;
      text-align: center;
      background-color: #3c78d8;
      color: white;
    }
  }
  tbody{
    td {
      border: 1px solid #E4E6EF;
      padding: 10px!important;
    }
    tr{
      &:last-child.add{
        &:hover{
          background-color: #3c78d8;
          transition: all 0.3s;
          td div{
            color: white!important;
          }
        }
      }
    }
  }
}