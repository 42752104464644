.stepper.stepper-links .stepper-nav .stepper-item.current .stepper-title {
  color: var(--color-header-table-custom);
}

.stepper.stepper-links .stepper-nav .stepper-item.current:after {
  background-color: var(--color-header-table-custom);
}

.btn-fill svg {
  margin: 0;
}

.mxh-65vh{
  max-height: 65vh;
}
.mxh-60vh{
  max-height: 70vh;
}

.science-council {
  .table-responsive {
    max-height: calc(100vh - 355px);
    min-height: 180px;
  }
}


#ds-hoi-dong-khoa-hoc #kt_table_users tr th, .table-topic #kt_table_users tr th{
  background-color: var(--color-header-table-custom) !important;
  color: white !important;

  &:hover {
    background-color: var(--color-header-table-custom) !important;
  }
}



