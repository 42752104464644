.delete {
  i {
    color: red;
    font-size: 1rem;
    cursor: pointer;
  }
}

.edit {
  i {
    color: #009ef7;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 15px;
  }
}