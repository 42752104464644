:root {
  --open: #52C41A;
  --close: #FF4D4F;
}

.status {
  width: 80%;
  margin: 0 auto;
  padding: 6px 12px;
  border-radius: 6px;
  color: white;

  &.open {
    background-color: var(--open);
  }

  &.close {
    background-color: var(--close);
  }
}

.btn-filter,
.btn-filter:hover,
.btn-filter:active,
.btn-filter:focus {
  font-weight: bold;
  color: var(--bs-btn-primary) !important;
  border-color: var(--bs-btn-primary) !important;

  i {
    color: var(--bs-btn-primary) !important;
  }
}

.table-responsive {
  max-height: 60vh;
  min-height: 300px;
}

#h-bang-ds-dot-dang-ky #kt_table_users tr th {
  background-color: var(--color-header-table-custom) !important;
  color: white !important;
  &:hover {
    background-color: var(--color-header-table-custom) !important;
  }
}